<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>基本资料</el-breadcrumb-item>
      <el-breadcrumb-item>客户管理</el-breadcrumb-item>
      <el-breadcrumb-item>修改客户管理</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <el-form :model="editcustomerForm" :rules="editcustomerFormRules"
               ref="editcustomerFormRef" label-width="130px" class="demo-dynamic">
        <el-form-item label="客户名称：" prop="c_name">
          <el-input v-model="editcustomerForm.c_name"></el-input>
        </el-form-item>

        <el-form-item label="客户级别：" prop="cl_id">
          <el-select v-model="editcustomerForm.cl_id" placeholder="请选择" @change="addNameSelectValue" clearable>
            <el-option
                v-for="item in customerlevelList"
                :key="item.cl_id"
                :label="item.cl_name"
                :value="item.cl_id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="业务员：" prop="c_salesman">
          <el-select v-model="editcustomerForm.c_salesman" placeholder="请选择" @change="addSalesmanValue">
            <el-option
                v-for="item in memberList"
                :key="item.m_id"
                :label="item.m_name"
                :value="item.m_id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="选择地址" prop="region">
          <el-cascader
              v-model="editcustomerForm.region" clearable @change="addWareHouseChanged"
              :options="listarea" :props="cascaderProps"
          ></el-cascader>
        </el-form-item>
        <el-form-item label="详细地址：" prop="c_address">
          <el-input v-model="editcustomerForm.c_address"></el-input>
        </el-form-item>
        <el-form-item label="备用信息：" prop="c_remark">
          <el-input v-model="editcustomerForm.c_remark"></el-input>
        </el-form-item>
<!--        <el-form-item label="结算方式：" prop="c_settlement">-->
<!--          <el-select v-model="editcustomerForm.c_settlement" placeholder="请选择" @change="addSettlementValue">-->
<!--            <el-option-->
<!--                v-for="item in settlementList"-->
<!--                :key="item.id"-->
<!--                :label="item.name"-->
<!--                :value="item.id">-->
<!--            </el-option>-->
<!--          </el-select>-->
<!--        </el-form-item>-->
        <el-form-item label="结算方式：" prop="c_settlement">
          <el-checkbox-group v-model="editcustomerForm.c_settlement">
            <el-checkbox v-for="item in settlementList" @change="addSettlementValue"
                         :key="item.id"
                         :label="item.id">{{item.name}}</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
<!--        <el-form-item label="发票抬头：" prop="c_invoice_title">-->
<!--          <el-input v-model="editcustomerForm.c_invoice_title"></el-input>-->
<!--        </el-form-item>-->
        <el-form-item label="纳税人识别号：" prop="c_taxpayer">
          <el-input v-model="editcustomerForm.c_taxpayer"></el-input>
        </el-form-item>
        <el-form-item label="地址：" prop="c_company_address">
          <el-input v-model="editcustomerForm.c_company_address"></el-input>
        </el-form-item>
        <el-form-item label="开户名称：" prop="c_account_name">
          <el-input v-model="editcustomerForm.c_account_name"></el-input>
        </el-form-item>
        <el-form-item label="开户银行：" prop="c_account_bank">
          <el-input v-model="editcustomerForm.c_account_bank"></el-input>
        </el-form-item>
        <el-form-item label="银行账号：" prop="c_bank_account">
          <el-input v-model="editcustomerForm.c_bank_account"></el-input>
        </el-form-item>

        <template v-for="(domain, index) in editcustomerForm.customercontacts">

          <el-form-item
              :label="'姓名' + index" :key="domain.key"
              :prop="'customercontacts.' + index + '.cc_name'"
              :rules="{required: true, message: '姓名不能为空', trigger: 'blur'}">
            <el-input v-model="domain.cc_name"></el-input>
          </el-form-item>
          <el-form-item
              :label="'电话' + index" :key="domain.key1"
              :prop="'customercontacts.' + index + '.cc_phone'">
            <el-input v-model="domain.cc_phone"></el-input>
          </el-form-item>
          <el-form-item
              :label="'职位' + index" :key="domain.key2"
              :prop="'customercontacts.' + index + '.cc_position'">
            <el-input v-model="domain.cc_position"></el-input>
          </el-form-item>
          <el-form-item
              :label="'邮箱' + index" :key="domain.key3"
              :prop="'customercontacts.' + index + '.cc_email'">
            <el-input v-model="domain.cc_email"></el-input>
          </el-form-item>
          <el-form-item :key="domain.key3">
            <el-button @click.prevent="removeDomain(domain)" class="removinput">删除</el-button>
          </el-form-item>

        </template>

      </el-form>
      <div class="addContacts">
        <el-button @click="addDomain">新增联系人</el-button>
      </div>
      <div class="dialog-footer">
        <el-button @click="editgo">取 消</el-button>
        <el-button type="primary" @click="addcustomerlevel" :loading="editloading">确 定</el-button>
      </div>

    </el-card>
  </div>
</template>
<script>
export default {
  data() {
    return {
      editloading:false,
      // 添加客户信息数据
      editcustomerForm: {
        customercontacts: [{
          cc_name: '',
          cc_phone: '',
          cc_email: '',
          cc_position: '',
        }],
        c_settlement:[],
      },
      //客户等级
      customerlevelList: [],
      //业务员
      memberList: [],
      //客户级别 当前选中
      addclidValue: '',
      //业务员  当前选中
      addcsalesmanvalue: '',
      //结算方式 当前选中
      addsettlementvalue: [],
      //省市区的
      listarea: [],
      selectListarea: [],
      editselectListarea: [],
      cascaderProps: {
        expandTrigger: 'hover',
        // checkStrictly:true,
        value: 'la_id',
        label: 'la_name',
        children: 'children'
      },
      //结算方式
      settlementList: [
        {id: 1, name: '预付'},
        {id: 2, name: '现付'},
        {id: 3, name: '后付'},
      ],
      editcustomerFormRules: {
        c_name: [
          {required: true, message: '请输入客户名称', trigger: 'blur'}
        ],
        // c_address: [
        //   {required: true, message: '请输入详细地址', trigger: 'blur'}
        // ],
        // c_remark: [
        //   {required: true, message: '请输入备用信息', trigger: 'blur'}
        // ],
        // c_invoice_title: [
        //   {required: true, message: '请输入发票抬头', trigger: 'blur'}
        // ],
        // c_taxpayer: [
        //   {required: true, message: '请输入纳税人识别号', trigger: 'blur'}
        // ],
        // c_company_address: [
        //   {required: true, message: '请输入地址', trigger: 'blur'}
        // ],
        // c_account_name: [
        //   {required: true, message: '请输入开户名称', trigger: 'blur'}
        // ],
        // c_account_bank: [
        //   {required: true, message: '请输入开户银行', trigger: 'blur'}
        // ],
        // c_bank_account: [
        //   {required: true, message: '请输入银行账号', trigger: 'blur'}
        // ],
        // cl_id:[
        //   {required: true, message: '请选择客户级别', trigger: 'change'}
        // ],
        // c_salesman:[
        //   {required: true, message: '请选择业务员', trigger: 'change'}
        // ],
        // region:[
        //   {required: true, message: '请选择地址', trigger: 'change'}
        // ],
        // c_settlement:[
        //   {required: true, message: '请选择结算方式', trigger: 'change'}
        // ],

      }
    }
  },
  created() {
    this.getcustomerlevelList()
    this.getmemberList()
    this.getWarehouseArea()
    this.geteditForm()
  },
  methods: {
    async geteditForm() {
      const {data: res} = await this.$http.get('customer/find/' + this.$route.query.editid)
      if (res.code != 200) return this.$message.error(res.msg)
      this.editcustomerForm = res.data
      //客户级别 当前选中
      // this.addclidValue = res.data.cl_id
      //业务员  当前选中
      // this.addcsalesmanvalue = res.data.c_salesman
      //结算方式 当前选中
      // this.addsettlementvalue = res.data.c_settlement
      this.selectListarea = res.data.region
      this.editcustomerForm.customercontacts = res.data.customercontacts
    },
    //获取客户级别
    async getcustomerlevelList() {
      const {data: res} = await this.$http.get('customerlevel/getMainList')
      if (res.code !== 200) {
        return this.$message.error(res.msg)
      }
      // 把数据列表 赋值
      this.customerlevelList = res.data
    },
    //获取业务员
    async getmemberList() {
      const {data: res} = await this.$http.get('member/getMainList')
      if (res.code !== 200) {
        return this.$message.error(res.msg)
      }
      // 把数据列表 赋值
      this.memberList = res.data
    },
    //获取省市区
    async getWarehouseArea() {
      const {data: res} = await this.$http.get('listarea')
      if (res.code != 200) return this.$message.error('获取省市区数据失败')
      this.listarea = res.data
      console.log(this.listarea)
    },
    // 添加 监听级联选择器 选中节点变化时触发
    addWareHouseChanged() {
      // console.log(this.selectListarea)
      this.editcustomerForm.c_province = this.editcustomerForm.region[0]
      this.editcustomerForm.c_city = this.editcustomerForm.region[1]
      this.editcustomerForm.c_area = this.editcustomerForm.region[2]
    },
    //select name发生变化时触发
    addNameSelectValue() {
      // this.editcustomerForm.cl_id = this.addclidValue
    },
    //select salesman发生变化时触发
    addSalesmanValue() {
      // this.editcustomerForm.c_salesman = this.addcsalesmanvalue
    },
    //select settlement发生变化时触发
    addSettlementValue() {
      // this.editcustomerForm.c_settlement = this.addsettlementvalue
    },
    //点击提交
    addcustomerlevel() {
      this.$refs.editcustomerFormRef.validate(async valid => {
        if (!valid) return
        this.editloading=true
        const {data: res} = await this.$http.post('customer/edit',
            this.editcustomerForm)
        this.editloading=false
        if (res.code !== 200) return this.$message.error(res.msg)
        this.$message.success(res.msg)
        this.$router.go(-1)

      })
    },
    addDomain() {
      this.editcustomerForm.customercontacts.push({
        cc_name: '',
        cc_phone:'',
        cc_position:'',
        cc_email:'',
        key: Date.now(),
        key1: Date.now() + 1,
        key2: Date.now() + 2,
        key3: Date.now() + 3
      });
    },
    removeDomain(item) {
      var index = this.editcustomerForm.customercontacts.indexOf(item)
      if (index !== -1) {
        this.editcustomerForm.customercontacts.splice(index, 1)
      }
    },
    editgo() {
      this.$router.go(-1)

    }

  }
}
</script>
<style lang="less" scoped>
.elcard{
  height: 80vh;
  overflow: auto;
}
.addContacts {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}

.dialog-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
::-webkit-scrollbar {
  width: 10px; /*对垂直流动条有效*/
}
/*定义滚动条的轨道颜色、内阴影及圆角*/
::-webkit-scrollbar-track{
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  background-color: #ffffff;
  border-radius: 3px;
}
/*定义滑块颜色、内阴影及圆角*/
::-webkit-scrollbar-thumb{
  border-radius: 7px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  background-color: #E8E8E8;
}
</style>
